import React from "react"
import {
  Layout,
  Paylater,
  SupplyChainFinance,
  TrustedFIPartners,
  SEO
} from "../../components"
import styles from "./financialServices-css-module.module.scss"
import fsbannerImg from "../../../static/fsbannerImg.png"
import solvScore from "../../../static/solvScore.png"

const services = () => {
  return (
    <>
      <Layout>
        <SEO
          title="BNPL | Small business finance | Small Business Financing"
          description="Solv enables MSMEs to get access to timely finance via a marketplace model. Solv is able to help small businesses get quick and easy access to credit."
        />
        <section className={styles.container}>
          <div className={styles.about}>
            <img src={fsbannerImg} alt="logo" />
            <div className={styles.growthPlatform}>
              <div className={[styles.heading, "heading1SemiBold"].join(" ")}>
                Quick and easy access to finance
              </div>
              <div className={styles.description}>
                The Solv platform provides small businesses with various
                financing options to support their growth. We are a financial
                services marketplace backed by technology and a network
                comprising several banks and NBFCs.
              </div>
            </div>
          </div>
          <div className={styles.businessBg}>
            {/* <BusinessInstalmentLoan /> */}
            <Paylater />
          </div>
        </section>

        <section>
          <div className={styles.supplyChainBg}>
            <SupplyChainFinance />

            <div className={styles.solvScoreSection}>
              <img src={solvScore} alt="solvScore" />
              <div className={styles.scoreGap}>
                <div
                  className={[styles.scoreHeadings, "heading1Medium"].join(" ")}
                >
                  Solv Score
                </div>
                <div className={styles.details}>
                  Buyers and sellers on the Solv platform are distinguished by
                  their Solv Score. The Solv score of a business is based on
                  several parameters. These parameters include traditional data
                  like one’s credit bureau score, several forms of alternate
                  data, as well as behavioural / operational data based on one’s
                  transaction history on the Solv platform. The Solv Score
                  serves as an alternate credit score helping SMEs get easier
                  and faster access to credit and also helps businesses
                  establish trust on the Solv platform.
                </div>
              </div>
            </div>
            <TrustedFIPartners />
          </div>
        </section>
      </Layout>
    </>
  )
}

export default services
